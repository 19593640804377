import { MouseEventHandler, ReactElement, ReactNode } from 'react';

import { StyledNativeLinkWithRef } from './StyledNativeLinkWithRef';

type Props = {
  id?: string;
  link?: string | null | undefined;
  children?: ReactNode;
  className?: string;
  onClick?: MouseEventHandler;
  'data-qa-id'?: string;
};

export function ConditionalStyledLinkWithRef({
  id,
  link,
  children,
  className,
  onClick,
  ...props
}: Props): ReactElement {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!link) return <>{children}</>;

  return (
    <StyledNativeLinkWithRef
      data-qa-id={props['data-qa-id']}
      href={link}
      className={className}
      onClick={onClick}
    >
      {children}
    </StyledNativeLinkWithRef>
  );
}
